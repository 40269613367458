import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="bg-white w-full p-8 md:p-12 shadow-lg border border-gray-200 text-center">
            <h2 className="text-3xl font-bold mb-6 text-gray-900">Page Not Found (404)</h2>
            <p className="mb-6 text-lg text-gray-700">Sorry, the page you are looking for does not exist.</p>
            <Link to="/" className="bg-blue-600 text-white py-3 px-8 rounded-lg">
                Return to Home
            </Link>
        </div>
    );
};

export default NotFound;
