import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Quiz from "./components/Quiz";
import Result from "./components/Result";
import NotFound from "./components/NotFound"; // Import de la page 404

function App() {
  return (
    <Router>
      <div className="bg-[#fffefe] h-screen flex justify-center items-center">
        <div className="container max-w-lg">
          <Routes>
            <Route path="/" element={<Quiz />} />
            <Route path="/result" element={<Result />} />
            <Route path="*" element={<NotFound />} /> {/* Gestion des erreurs 404 */}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
