import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "./Alert"; // Import du composant d'alerte
import Logo from "../assets/img/logo.svg"; // Import du logo
import questionsData from '../assets/data/questions.json'; // Importation des questions depuis le fichier JSON

const Quiz = () => {
    const navigate = useNavigate();

    // Initialisation des étapes et réponses depuis localStorage
    const [step, setStep] = useState(() => JSON.parse(localStorage.getItem('currentStep')) || 0);
    const [responses, setResponses] = useState(() => JSON.parse(localStorage.getItem('quizResponses')) || {});
    const [alerts, setAlerts] = useState([]);  // État pour gérer les alertes multiples
    const [questions, setQuestions] = useState(questionsData.questions);

    // Sauvegarde automatique des réponses et de l'étape dans localStorage à chaque mise à jour
    useEffect(() => {
        localStorage.setItem('currentStep', JSON.stringify(step));
        localStorage.setItem('quizResponses', JSON.stringify(responses));
    }, [step, responses]);

    // Fonction de mise à jour des réponses
    const updateResponses = useCallback((name, value) => {
        setResponses(prev => ({ ...prev, [name]: value }));
    }, []);

    //Vérification des réponses
    const validateResponse = useCallback(() => {
        const currentQuestion = questions[step];

        if (currentQuestion.type === "email") {
            // Vérification basique pour l'email (tu peux ajouter une regex plus stricte si besoin)
            return responses[currentQuestion.name] && responses[currentQuestion.name].includes('@');
        } else if (currentQuestion.type === "number") {
            // Vérifier si un nombre est présent et valide
            return responses[currentQuestion.name] && !isNaN(responses[currentQuestion.name]) && responses[currentQuestion.name] > 0;
        } else if (currentQuestion.type === "boolean" || currentQuestion.type === "select") {
            // Pour les types boolean et select, vérifier si une option est choisie
            return responses[currentQuestion.name] !== undefined;
        }

        return true;  // Pour les autres types, pas de validation spécifique
    }, [questions, step, responses]);

    // Fonction de retour à la question précédente
    const handlePrevious = useCallback(() => {
        const currentQuestion = questions[step];
        if (responses[currentQuestion.name]) {
            const updatedResponses = { ...responses };
            delete updatedResponses[currentQuestion.name]; // Suppression de la réponse de la question courante
            setResponses(updatedResponses);
            localStorage.setItem('quizResponses', JSON.stringify(updatedResponses));
        }
        setStep(prev => prev - 1);
    }, [step, responses, questions]);

    // Gestion de la progression du quiz
    const handleNext = useCallback((option) => {
        const currentQuestion = questions[step];

        // Cas où la question est de type email avec validation
        if (currentQuestion.type === "email") {
            // Vérifie si validation est définie et est une fonction avant de l'exécuter
            if (currentQuestion.validation && typeof currentQuestion.validation === 'function') {
                if (!currentQuestion.validation(option)) {
                    setAlerts(prevAlerts => [...prevAlerts, "Veuillez entrer un email valide."]);
                    return;
                }
            }
            updateResponses(currentQuestion.name, option);
        } else if (option) {
            updateResponses(currentQuestion.name, option.value);
        }

        // Passage à la question suivante ou génération du résultat
        if (step === questions.length - 1) {
            setTimeout(() => {
                navigate("/result", { state: { ...responses } });
            }, 100);
        } else {
            setStep(prev => prev + 1);
        }
    }, [step, responses, navigate, questions, updateResponses]);

    // Gestion du rendu des options pour les questions select/boolean
    const renderOptions = (question) => {
        if (question.type === "boolean") {
            // Pour les questions de type boolean, on affiche automatiquement Oui/Non côte à côte
            return (
                <div className="options flex space-x-4 w-full justify-between">
                    <button
                        className="block w-1/2 bg-blue-600 text-white font-medium py-3 px-8 transition-all duration-300 ease-in-out transform hover:scale-105"
                        onClick={() => handleNext({ value: true })}
                    >
                        Oui
                    </button>
                    <button
                        className="block w-1/2 bg-blue-600 text-white font-medium py-3 px-8 transition-all duration-300 ease-in-out transform hover:scale-105"
                        onClick={() => handleNext({ value: false })}
                    >
                        Non
                    </button>
                </div>
            );
        } else if (question.options && question.options.length > 0) {
            return question.options.map((option, index) => (
                <button
                    key={index}
                    className="block w-full bg-blue-600 text-white font-medium py-3 px-8 transition-all duration-300 ease-in-out transform hover:scale-105"
                    onClick={() => handleNext(option)}
                >
                    {option.text || option.label}
                </button>
            ));
        }

        return null;
    };

    const renderQuestion = () => {
        const currentQuestion = questions[step];

        // Vérification du type de question
        switch (currentQuestion.type) {
            case "email":
                return (
                    <div>
                        <input
                            type="email"
                            className="block w-full bg-gray-100 text-black font-medium py-3 px-4 mb-4"
                            placeholder="Entrez votre email"
                            onChange={(e) => updateResponses(currentQuestion.name, e.target.value)}
                            value={responses[currentQuestion.name] || ""}
                        />
                        <button
                            className="block w-full bg-blue-600 text-white font-medium py-3 px-8"
                            onClick={() => handleNext(responses[currentQuestion.name])}
                            disabled={!validateResponse()}  // Désactiver si la validation échoue
                        >
                            Suivant
                        </button>
                    </div>
                );
            case "select":
            case "boolean":
                return (
                    <div className="options flex flex-col space-y-4">
                        {renderOptions(currentQuestion)}
                    </div>
                );
            case "number":
                return (
                    <div>
                        <input
                            type="number"
                            className="block w-full bg-gray-100 text-black font-medium py-3 px-4 mb-4"
                            placeholder={currentQuestion.question}
                            onChange={(e) => updateResponses(currentQuestion.name, e.target.value)}
                            value={responses[currentQuestion.name] || ""}
                            min={currentQuestion.min}
                            max={currentQuestion.max}
                        />
                        <button
                            className="block w-full bg-blue-600 text-white font-medium py-3 px-8"
                            onClick={() => handleNext({ value: responses[currentQuestion.name] })}
                            disabled={!validateResponse()}  // Désactiver si la validation échoue
                        >
                            Suivant
                        </button>
                    </div>
                );
            default:
                return <p>Type de question non reconnu</p>;
        }
    };

    return (
        <div className="bg-white w-full p-8 md:p-12 shadow-lg border border-gray-200 transition-all duration-500 ease-in-out hover:shadow-2xl">
            {/* Centrer le logo */}
            <div className="flex justify-center mb-8">
                <img src={Logo} alt="Logo" className="w-50 h-auto" />
            </div>

            {/* Indicateur de progression */}
            <div className="flex items-center justify-center mb-6">
                {questions.map((_, index) => (
                    <div
                        key={index}
                        className={`h-2 w-8 mx-1 ${index <= step ? 'bg-blue-600' : 'bg-gray-300'}`}
                    ></div>
                ))}
            </div>

            {/* Rendu de la question */}
            {questions[step] ? (
                <>
                    <h2 className="text-2xl font-semibold mb-6 text-center text-gray-900">
                        {questions[step].question}
                    </h2>
                    {renderQuestion()}

                    {/* Bouton Précédent */}
                    {step > 0 && (
                        <button
                            className="block w-full bg-gray-600 text-white font-medium py-3 px-8 mt-4"
                            onClick={handlePrevious}
                        >
                            Précédent
                        </button>
                    )}
                </>
            ) : (
                <p>Chargement des questions...</p>
            )}

            {/* Affichage des alertes en bas à droite */}
            <div className="fixed bottom-4 right-4 space-y-2 z-50">
                {alerts.map((alertMessage, index) => (
                    <Alert
                        key={index}
                        message={alertMessage}
                        onClose={() => setAlerts(alerts.filter((_, i) => i !== index))}
                    />
                ))}
            </div>
        </div>
    );
};

export default Quiz;
